import axios from 'axios';
import ActionResponseData from '@/js/components/forms/action_response_data';

export default class ActionHandler {
  constructor() {
    this.states = {
      idle: 'STATE_IDLE',
      awaitingRequest: 'STATE_AWAITING_REQUEST',
    };

    this.currentState = this.states.idle;
  }

  /**
   * form has been submitted
   * @param url {string}
   * @param formData {FormData}
   * @param action {string|null}
   */
  send({ url, formData, action = null }) {
    // Skip when is busy
    if (this.isBusy()) return;

    // disallow to send again
    this.currentState = this.states.awaitingRequest;

    if (action) {
      formData.append('action', action);
    }

    // send request
    axios.post(url, formData)
      .then(this.onRequestSuccess.bind(this))
      .catch(this.onRequestError.bind(this));
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    // allow to send
    this.currentState = this.states.idle;
    (new ActionResponseData(response.data)).process();
  }

  /**
   * request has failed
   * @param error {Object}
   */
  onRequestError(error) {
    console.error(error);

    // allow to send
    this.currentState = this.states.idle;
  }

  isBusy() {
    return this.currentState === this.states.awaitingRequest;
  }
}
