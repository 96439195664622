import ActionHandler from '@/js/components/forms/action_handler';

export default class ActionComponent extends ActionHandler {
  /**
   * @param button {HTMLButtonElement}
   * @param url {string}
   * @param action {string}
   */
  constructor({ element, url, action }) {
    super();

    this.dom = {
      element,
    };

    this.props = {
      url,
      action,
    };

    this.events = {
      onElementClick: this.onElementClick.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.element) {
      this.dom.element.addEventListener('click', this.events.onElementClick);
    }
  }

  onElementClick() {
    const formData = new FormData();

    Object.keys(this.dom.element.dataset)
      .forEach((key) => {
        const name = key.replace('request', ''); // Remove request from attribute name

        // Skip the url attribute
        if (name === 'Url') {
          return;
        }

        // add the attribute to the form data
        formData.append(name, this.dom.element.dataset[key]);
      });

    this.send({
      url: this.props.url,
      action: this.props.action,
      formData,
    });
  }

  onRequestSuccess(response) {
    super.onRequestSuccess(response);
    this.dom.element.dispatchEvent(new CustomEvent('success'));
  }

  onRequestError(error) {
    super.onRequestError(error);
    this.dom.element.dispatchEvent(new CustomEvent('error'));
  }
}
