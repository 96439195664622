import Navigation from '@/js/components/dropdowns/navigation';

const navigationDropdowns = document.querySelectorAll('.c-nav-dropdown');

if (navigationDropdowns) {
  /* eslint-disable no-new */
  navigationDropdowns.forEach((navigationDropdown) => {
    new Navigation(navigationDropdown);
  });
}
