import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { French } from 'flatpickr/dist/l10n/fr';

export default class Datepicker {
  /**
   * @param element
   * @param lang
   * @param minDate
   * @param maxDate
   */
  constructor({
    element, lang, minDate, maxDate,
  }) {
    this.dom = {
      element,
      lang,
      minDate: (minDate === undefined) ? null : minDate,
      maxDate: (maxDate === undefined) ? null : maxDate,
    };

    this.mount();
  }

  mount() {
    if (this.dom.lang === 'nl') {
      flatpickr.localize(Dutch);
    } else {
      flatpickr.localize(French);
    }

    flatpickr(this.dom.element, {
      enableTime: false,
      dateFormat: 'd/m/Y',
      minDate: this.dom.minDate,
      maxDate: this.dom.maxDate,
    });
  }
}
