export default class Navigation {
  constructor(navigationDropdown) {
    this.dom = {
      navigationDropdown,
      timer: null,
      wait: 10,
      forceCloseEvent: document.createEvent('Event'),
      mainLink: navigationDropdown.querySelector('.navbar-dropdown__main-link'),
      breakpoint: 991,
    };

    this.mount();
  }

  mount() {
    this.dom.forceCloseEvent.initEvent('forceClose', true, true);
    this.dom.navigationDropdown.addEventListener('mouseenter', this.enter.bind(this));
    this.dom.navigationDropdown.addEventListener('mouseleave', this.leave.bind(this));
    this.dom.navigationDropdown.addEventListener('forceClose', this.close.bind(this));
    this.dom.mainLink.addEventListener('click', this.linkClick.bind(this));
  }

  linkClick(e) {
    if (window.innerWidth < (this.dom.breakpoint + 1)) {
      e.preventDefault();
    }

    this.resetAriaExpanded();
    this.setAriaExpanded();
  }

  enter() {
    if (window.innerWidth > this.dom.breakpoint) {
      this.closeAll.bind(this);
      this.setAriaExpanded();
      this.dom.navigationDropdown.classList.add('open');

      if (this.dom.timer) {
        clearTimeout(this.dom.timer);
      }
    }
  }

  leave() {
    if (window.innerWidth > this.dom.breakpoint) {
      this.dom.timer = setTimeout(this.close.bind(this), this.dom.wait);
    }
  }

  close() {
    this.dom.mainLink.setAttribute('aria-expanded', 'false');
    this.dom.navigationDropdown.classList.remove('open');
    this.dom.timer = null;
  }

  closeAll() {
    [...document.querySelectorAll('.c-nav-dropdown.open')].forEach((el) => {
      if (el !== this.dom.navigation) {
        el.dispatchEvent(this.dom.forceCloseEvent);
      }
    });
  }

  setAriaExpanded() {
    if (this.dom.mainLink.getAttribute('aria-expanded') === 'true') {
      this.dom.mainLink.setAttribute('aria-expanded', 'false');
    } else {
      this.dom.mainLink.setAttribute('aria-expanded', 'true');
    }
  }

  resetAriaExpanded() {
    [...document.querySelectorAll('.navbar-dropdown__main-link[aria-expanded="true"]')].forEach((el) => {
      if (el !== this.dom.mainLink) {
        el.dispatchEvent(this.dom.forceCloseEvent);
      }
    });
  }
}
