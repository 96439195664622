/**
 * Check if hostname is local or external
 * @param host {string}
 * @returns {boolean}
 */
export function usesSubFolder(host) {
  return !(host.includes('.') || host.includes(':'));
}

/**
 * Get root URL
 * @returns {string}
 */
function getRoot() {
  const {
    protocol, pathname, host,
  } = window.location;
  let tempRoot = `${protocol}//${host}/`;

  if (usesSubFolder(host)) {
    const segments = pathname.split('/');
    tempRoot += `${segments[1]}/`;
  }

  return tempRoot;
}

export const root = getRoot();

export const rootWithLanguage = `${getRoot() + document.documentElement.lang}/`;
