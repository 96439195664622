import CookiesNotice from '@/js/components/cookies/cookies';
import InfoModal from '@/js/components/info_modal/info_modal';

const cookiesNoticeEl = document.getElementById('cookies-notice');
const cookiesNoticeModal = document.getElementById('cookie-customizer-modal');

// Cookies notice
if (cookiesNoticeEl || cookiesNoticeModal) {
  const cookieNotice = new CookiesNotice(cookiesNoticeEl, cookiesNoticeModal);
  const infoModal = document.getElementById('infoModal');

  if (infoModal) {
    cookieNotice.dom.cookieNotice.addEventListener('cookies_selected', () => {
      // eslint-disable-next-line no-new
      new InfoModal(infoModal);
    });
  }
}
