/* eslint-disable import/no-import-module-exports, no-unused-vars */
import './styles/index.scss';

import {
  Alert, Dropdown, Offcanvas, Modal, Tooltip, Popover, Collapse,
} from 'bootstrap';

// views
import './js/views';

// load all icons
(function loadIcons(r) {
  r.keys().forEach(r);
}(require.context('./img/icons', true, /\.svg$/)));

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) module.hot.accept();
