export function highlight(text, matches, keys) {
  let highlightedText = '';
  let currentIndex = 0;

  matches.forEach((match) => {
    if (keys.includes(match.key)) {
      // add non-matching characters
      highlightedText += text.slice(currentIndex, match.indices[0][0]);

      // wrap matching characters in a <span>
      const matchingChars = text.slice(match.indices[0][0], match.indices[0][1] + 1);
      highlightedText += `<span class="highlight">${matchingChars}</span>`;

      currentIndex = match.indices[0][1] + 1;
    }
  });

  // add remaining non-matching characters
  highlightedText += text.slice(currentIndex);

  return highlightedText;
}

export default {
  highlight,
};
