import { Modal } from 'bootstrap';

export default class InfoModal {
  constructor(modal) {
    this.dom = {
      modal,
    };

    this.events = {};

    this.components = {
      modal: null,
    };

    this.mount();
  }

  mount() {
    this.components.modal = new Modal(this.dom.modal);
    this.components.modal.show();
  }
}
